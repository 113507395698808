import React, { useState } from 'react'

import {
  CheckboxButton,
  CheckboxButtonGroup,
  FormText
} from '@te-digi/styleguide'

const CheckboxButtonGroupWithRadioExample = () => {
  const [value, setValue] = useState('a')

  return (
    <CheckboxButtonGroup label="Lorem ipsum dolor sit amet">
      <CheckboxButton
        bulletContent="1"
        checked={value === 'a'}
        id="checkbox-button-group-radio-example-1"
        name="checkbox-button-group-radio"
        onChange={() => setValue('a')}
        type="radio"
        value={'a'}
      >
        Lorem ipsum dolor sit amet
      </CheckboxButton>
      <CheckboxButton
        bulletContent="2"
        checked={value === 'b'}
        id="checkbox-button-group-radio-example-2"
        name="checkbox-button-group-radio"
        onChange={() => setValue('b')}
        type="radio"
        value={'b'}
      >
        Consectetuer adipiscing elit
      </CheckboxButton>
      <CheckboxButton
        bulletContent="3"
        checked={value === 'c'}
        id="checkbox-button-group-radio-example-3"
        name="checkbox-button-group-radio"
        onChange={() => setValue('c')}
        type="radio"
        value={'c'}
      >
        Sed posuere interdum sem
      </CheckboxButton>
      <FormText>Valittu: {value}</FormText>
    </CheckboxButtonGroup>
  )
}

export { CheckboxButtonGroupWithRadioExample }
