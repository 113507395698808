import React, { useState } from 'react'

import { CheckboxButton, FormGroup, Stack } from '@te-digi/styleguide'

const CheckboxButtonRadioExample = () => {
  const [value, setValue] = useState(1)

  return (
    <FormGroup noMargin>
      <Stack gap="md">
        <CheckboxButton
          bulletContent="1"
          checked={value === 1}
          id="checkbox-button-radio-example-1"
          name="checkbox-button-radio"
          onChange={() => setValue(1)}
          type="radio"
          value={1}
        >
          Lorem ipsum dolor sit amet
        </CheckboxButton>
        <CheckboxButton
          bulletContent="2"
          checked={value === 2}
          id="checkbox-button-radio-example-2"
          name="checkbox-button-radio"
          onChange={() => setValue(2)}
          type="radio"
          value={2}
        >
          Consectetuer adipiscing elit
        </CheckboxButton>
        <CheckboxButton
          bulletContent="3"
          checked={value === 3}
          id="checkbox-button-radio-example-3"
          name="checkbox-button-radio"
          onChange={() => setValue(3)}
          type="radio"
          value={3}
        >
          Sed posuere interdum sem
        </CheckboxButton>
      </Stack>
    </FormGroup>
  )
}

export { CheckboxButtonRadioExample }
