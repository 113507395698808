import React, { useState } from 'react'

import {
  CheckboxButton,
  CheckboxButtonGroup,
  FormText
} from '@te-digi/styleguide'

const CheckboxButtonGroupWithErrorExample = () => {
  const [isChecked1, setIsChecked1] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)
  const [isChecked3, setIsChecked3] = useState(false)

  return (
    <CheckboxButtonGroup
      error="Error"
      label="Lorem ipsum dolor sit amet"
    >
      <CheckboxButton
        bulletContent="1"
        checked={isChecked1}
        id="checkbox-button-error-example-1"
        name="checkbox-button"
        onChange={() => setIsChecked1(isChecked => !isChecked)}
        value={1}
      >
        Lorem ipsum dolor sit amet
      </CheckboxButton>
      <CheckboxButton
        bulletContent="2"
        checked={isChecked2}
        id="checkbox-button-error-example-2"
        name="checkbox-button"
        onChange={() => setIsChecked2(isChecked => !isChecked)}
        value={2}
      >
        Consectetuer adipiscing elit
      </CheckboxButton>
      <CheckboxButton
        bulletContent="3"
        checked={isChecked3}
        id="checkbox-button-error-example-3"
        name="checkbox-button"
        onChange={() => setIsChecked3(isChecked => !isChecked)}
        value={3}
      >
        Sed posuere interdum sem
      </CheckboxButton>
      <FormText>
        Valittu: {[isChecked1, isChecked2, isChecked3]?.join(', ')}
      </FormText>
    </CheckboxButtonGroup>
  )
}

export { CheckboxButtonGroupWithErrorExample }
