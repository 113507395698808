import React from 'react'

import {
  CheckboxButton,
  CheckboxButtonGroup,
  FormGroup,
  List,
  ListItem,
  Paragraph,
  Stack
} from '@te-digi/styleguide'

import { CheckboxButtonCheckboxExample } from '../../examples/react/CheckboxButtonCheckboxExample'
import { CheckboxButtonExample2 } from '../../examples/react/CheckboxButtonExample2'
import { CheckboxButtonRadioExample } from '../../examples/react/CheckboxButtonRadioExample'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import IconHTMLExample from '../../examples/html/IconHTMLExample'
import { Code } from '../../components/Code'
import { CheckboxButtonGroupDisabledExample } from '../../examples/react/CheckboxButtonGroupDisabledExample'
import { CheckboxButtonGroupExample } from '../../examples/react/CheckboxButtonGroupExample'
import { CheckboxButtonGroupWithErrorExample } from '../../examples/react/CheckboxButtonGroupWithErrorExample'
import { CheckboxButtonGroupWithRadioExample } from '../../examples/react/CheckboxButtonGroupWithRadioExample'
import { CheckboxButtonGroupWithoutLabelExample } from '../../examples/react/CheckboxButtonGroupWithoutLabelExample'
import { SectionHeading } from '../../components/SectionHeading'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="CheckboxButton"
    components={[
      { component: CheckboxButton },
      { component: CheckboxButtonGroup }
    ]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section title="CheckboxButton">
      <Playground example={CheckboxButtonCheckboxExample} />
      <Playground format="html">
        <div className="form-group mb-0">
          <div className="checkbox-button">
            <input
              className="checkbox-button-input"
              id="checkbox-button-html-example-1"
              name="checkbox-button-html-example-1"
              type="checkbox"
            />
            <label
              className="checkbox-button-label"
              htmlFor="checkbox-button-html-example-1"
            >
              <span className="checkbox-button-content">
                Lorem ipsum dolor sit amet
              </span>
              <span className="checkbox-button-checkmark">
                <IconHTMLExample
                  name="CheckIcon"
                  size="lg"
                />
              </span>
            </label>
          </div>
        </div>
      </Playground>

      <SectionHeading>Radio</SectionHeading>
      <Playground example={CheckboxButtonRadioExample} />
      <SectionHeading>Disabloitu</SectionHeading>

      <Playground>
        <FormGroup noMargin>
          <Stack gap="md">
            <CheckboxButton
              bulletContent="1"
              disabled
              id="checkbox-button-example-disabled-1"
              name="checkbox-button-example-disabled-1"
            >
              Disabled
            </CheckboxButton>
            <CheckboxButton
              bulletContent="1"
              checked
              disabled
              id="checkbox-button-example-disabled-2"
              name="checkbox-button-example-disabled-2"
            >
              Disabled
            </CheckboxButton>
            <CheckboxButton
              bulletContent="1"
              disabled
              id="checkbox-button-example-disabled-3"
              name="checkbox-button-example-disabled-3"
              type="radio"
            >
              Disabled
            </CheckboxButton>
            <CheckboxButton
              bulletContent="1"
              checked
              disabled
              id="checkbox-button-example-disabled-4"
              name="checkbox-button-example-disabled-4"
              type="radio"
            >
              Disabled
            </CheckboxButton>
          </Stack>
        </FormGroup>
      </Playground>
      <Playground format="html">
        <div className="form-group mb-0">
          <div className="checkbox-button">
            <input
              className="checkbox-button-input"
              disabled
              id="checkbox-button-html-disabled-example-1"
              name="checkbox-button-html-disabled-example"
              type="checkbox"
            />
            <label
              className="checkbox-button-label"
              htmlFor="checkbox-button-html-disabled-example-1"
            >
              <span className="checkbox-button-content">
                Lorem ipsum dolor sit amet
              </span>
              <span className="checkbox-button-checkmark">
                <IconHTMLExample
                  name="CheckIcon"
                  size="lg"
                />
              </span>
            </label>
          </div>
          <div className="mb-md" />
          <div className="checkbox-button">
            <input
              className="checkbox-button-input"
              checked
              disabled
              id="checkbox-button-html-disabled-example-2"
              name="checkbox-button-html-disabled-example"
              type="checkbox"
            />
            <label
              className="checkbox-button-label"
              htmlFor="checkbox-button-html-disabled-example-2"
            >
              <span className="checkbox-button-content">
                Lorem ipsum dolor sit amet
              </span>
              <span className="checkbox-button-checkmark">
                <IconHTMLExample
                  name="CheckIcon"
                  size="lg"
                />
              </span>
            </label>
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="CheckboxButtonGroup">
      <Paragraph>
        <Code>CheckboxButtonGroup</Code>-komponentilla voi luoda{' '}
        <Code>CheckboxButton</Code>
        -ryhmiä. Sitä voi käyttää virheviestien näyttämiseen ja{' '}
        <Code>CheckboxButton</Code>ien disabloimiseen ryhmänä.{' '}
        <Code>CheckboxButton</Code>eita voi käyttää myös ilman ryhmää.
      </Paragraph>
      <Playground example={CheckboxButtonGroupExample} />

      <SectionHeading>Radio</SectionHeading>
      <Playground example={CheckboxButtonGroupWithRadioExample} />
      <SectionHeading>Error</SectionHeading>
      <Playground example={CheckboxButtonGroupWithErrorExample} />
      <SectionHeading>Ilman labelia</SectionHeading>
      <Playground example={CheckboxButtonGroupWithoutLabelExample} />
      <SectionHeading>Disabloitu</SectionHeading>
      <Playground example={CheckboxButtonGroupDisabledExample} />
    </Section>
    <Section title="Valinnan jälkeen esiin tuleva lisäsisältö">
      <Playground example={CheckboxButtonExample2} />
    </Section>
    <Section title="Saavutettavuus">
      <List variant="unordered">
        <ListItem>Varmista, että ryhmällä on kuvaava nimi.</ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
