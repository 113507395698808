import React, { Fragment, useState } from 'react'

import {
  CheckboxButton,
  FormGroup,
  Label,
  Paragraph,
  Stack
} from '@te-digi/styleguide'

const ITEMS = [
  {
    label: 'En osaa',
    value: 0
  },
  {
    label: 'Tyydyttävä',
    value: 1,
    description:
      'Pystyn keskustelemaan ja selviän jokapäiväisessä suullisessa viestinnässä.'
  },
  {
    label: 'Hyvä',
    value: 2,
    description: 'Ilmaisen itseäni sujuvasti.'
  },
  {
    label: 'Erittäin hyvä',
    value: 3,
    description:
      'Ymmärrän erilaisia ilmaisuja ja kielellisiä vivahteita. Esitän ajatukseni selkeästi ja pärjään ammatillisessa keskustelussa.'
  },
  {
    label: 'Äidinkieli',
    value: 4,
    description: 'Puhun kieltä äidinkielenäni.'
  }
]

const CheckboxButtonExample2 = () => {
  const [value, setValue] = useState(0)

  return (
    <FormGroup
      ariaLabelledby="checkbox-button-radio-example-label"
      noMargin
    >
      <Label id="checkbox-button-radio-example-label">
        Puhuminen ja kuullun ymmärtäminen: valitse sopiva taso
      </Label>
      <Stack gap="md">
        {ITEMS.map(option => (
          <Fragment key={option.value}>
            <CheckboxButton
              bulletContent={option.value.toString()}
              checked={option.value === value}
              collapsibleContent={
                option.description ? (
                  <Paragraph noMargin>{option.description}</Paragraph>
                ) : undefined
              }
              id={`checkbox-button-radio-${option.value}`}
              name="checkbox-button-radio-example-2"
              onChange={() => setValue(option.value)}
              type="radio"
              value={option.value}
            >
              {option.label}
            </CheckboxButton>
          </Fragment>
        ))}
      </Stack>
    </FormGroup>
  )
}

export { CheckboxButtonExample2 }
