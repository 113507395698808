import React, { useState } from 'react'

import { CheckboxButton, FormGroup, Stack } from '@te-digi/styleguide'

const CheckboxButtonCheckboxExample = () => {
  const [isChecked1, setIsChecked1] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)
  const [isChecked3, setIsChecked3] = useState(false)

  return (
    <FormGroup noMargin>
      <Stack gap="md">
        <CheckboxButton
          checked={isChecked1}
          id="checkbox-button-checkbox-example-1"
          name="checkbox-button-checkbox-example-1"
          onChange={() => setIsChecked1(isChecked => !isChecked)}
        >
          Lorem ipsum dolor sit amet
        </CheckboxButton>
        <CheckboxButton
          checked={isChecked2}
          id="checkbox-button-checkbox-example-2"
          name="checkbox-button-checkbox-example-2"
          onChange={() => setIsChecked2(isChecked => !isChecked)}
        >
          Consectetuer adipiscing elit
        </CheckboxButton>
        <CheckboxButton
          checked={isChecked3}
          id="checkbox-button-checkbox-example-3"
          name="checkbox-button-checkbox-example-3"
          onChange={() => setIsChecked3(isChecked => !isChecked)}
        >
          Sed posuere interdum sem
        </CheckboxButton>
      </Stack>
    </FormGroup>
  )
}

export { CheckboxButtonCheckboxExample }
